$colors: (
  'red': #f44336,
  'pink': #e91e63,
  'purple': #6f42c1,
  'indigo': #3f51b5,
  'blue': #2196f3,
  'cyan': #00bcd4,
  'teal': #009688,
  'green': #4caf50,
  'yellow': #ffe821,
  'orange': #fd7e14,
  'deep-orange': #ff5722,
  'brown': #795548,
  'grey': #9e9e9e,
  'black': #000000,
  'white': #ffffff,
) !default;

$linear-colors: (
  'green': linear-gradient(45deg, #9ce89d, #cdfa7e),
  'orange': linear-gradient(135deg, #ffc480, #ff763b),
  'cyan': linear-gradient(45deg, #72c2ff, #86f0ff),
  'red': linear-gradient(316deg, #fc5286, #fbaaa2),
  'purple': linear-gradient(230deg, #759bff, #843cf6),
  'purple-dark': linear-gradient(45deg, #a52dd8, #e29bf1),
  'card1': linear-gradient(to left, #3a7bd5, #3a6073),
  'card2': linear-gradient(to right, #c33764, #1d2671),
  'card3': linear-gradient(to left, #134e5e, #71b280),
  'card4': linear-gradient(to left, #d38312, #a83279),
);

//Fonts Family
$navbar-font-family: 'Roboto', sans-serif;
$sidebar-font-family: 'Roboto', sans-serif;
