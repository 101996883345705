// Bordered & Pulled
// -------------------------

.#{$fa-css-prefix}-border {
  border: solid 0.08em $fa-border-color;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.#{$fa-css-prefix}-pull-left {
  float: left;
}
.#{$fa-css-prefix}-pull-right {
  float: right;
}

.#{$fa-css-prefix},
.fas,
.far,
.fal,
.fab {
  &.#{$fa-css-prefix}-pull-left {
    margin-right: 0.3em;
  }
  &.#{$fa-css-prefix}-pull-right {
    margin-left: 0.3em;
  }
}
