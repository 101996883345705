﻿/*
 *  Document   : alert.scss
 *  Author     : RedStar Template
 *  Description: This scss file for alert style classes
 */
.alert {
  @include border-radius(0);
  @include box-shadow(none);
  border: none;
  color: #fff !important;

  b {
    font-weight: normal;
  }

  .alert-link {
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
  }
}

.alert-success {
  background-color: rgba(23, 103, 73, 0.8);
}

.alert-info {
  background-color: rgba(92, 92, 92, 0.8);
}

.alert-warning {
  background-color: rgba(255, 178, 54, 0.8);
}

.alert-danger {
  background-color: rgba(101, 73, 39, 0.8);
}

.alert-dismissible {
  .close {
    color: #fff;
    opacity: 1;
    border: none;
    text-shadow: none;
  }
}
